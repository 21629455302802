<template>
  <section>
    <breadcrumb :title="$t('accounting')" :subtitle="$t('daily_credit')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters is-flex is-justify-content-space-between is-align-items-center">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       v-model="search"
                       type="search"
                       icon="magnify"
                       icon-clickable
                       @icon-click="searchIconClick"
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div class="ml-30 ">
            <b-field>
              <b-datepicker
                  placeholder=""
                  v-model="dates"
                  range>
              </b-datepicker>
            </b-field>
          </div>
        </div>
        <div>
          <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
              <div class="is-flex is-align-items-center">
                <img class="img-dolar mr-15 cursor-pointer" :src="dolar" alt="">
                <span v-if="currentCurrency" class="mx-10 currency">{{ currentCurrency }}</span>
              </div>
            </template>
            <b-dropdown-item v-for="(item, idx) in currencyList" :key="idx"
                             @click="getDailyCreditByCurrency(item)"
                             aria-role="listitem">{{item}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="report.data"
          ref="table"
          :paginated="false"
          per-page="per_page"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
        <b-table-column field="distributor" :label="$t('distributor')" v-slot="props">
        <span class="col name">
          {{ props.row.dealerName }}
        </span>
        </b-table-column>
        <b-table-column field="initial_balance" :label="$t('initial_balance')" v-slot="props">
          <b-tag type="is-tag-success " rounded>
            <span v-if="currentCurrency === CURRENCY.USD" class="text-status success is-uppercase">
          {{ props.row.initialAmountUSD }}
        </span>
            <span v-else-if="currentCurrency === CURRENCY.CUP" class="text-status success is-uppercase">
          {{ props.row.initialAmount }}
        </span>
        <span v-else class="text-status success is-uppercase">
          {{ props.row.initialAmountMLC }}
        </span>
          </b-tag>
        </b-table-column>
        <b-table-column field="received" :label="$t('received')" v-slot="props">
        <span v-if="currentCurrency === CURRENCY.USD" class="col received">
          {{ props.row.receivedAmountUSD }}
        </span>
          <span v-else-if="currentCurrency === CURRENCY.CUP" class="col received">
          {{ props.row.receivedAmount }}
        </span>
          <span v-else class="col received">
          {{ props.row.receivedAmountMLC }}
        </span>
        </b-table-column>
        <b-table-column field="delivered" :label="$t('delivered')" v-slot="props">
          <b-tag type="is-tag-success " rounded>
            <span v-if="currentCurrency === CURRENCY.USD" class="text-status success"> {{ props.row.deliveredAmountUSD }}</span>
            <span v-else-if="currentCurrency === CURRENCY.CUP" class="text-status success"> {{ props.row.deliveredAmount }}</span>
            <span v-else class="text-status success"> {{ props.row.deliveredAmountMLC }}</span>
          </b-tag>
        </b-table-column>
        <b-table-column field="shipping" :label="$t('shipping')" v-slot="props">
        <span v-if="currentCurrency === CURRENCY.USD" class="col common">
          {{ props.row.totalRemittancesUSD }}
        </span>
          <span v-else-if="currentCurrency === CURRENCY.CUP" class="col common">
          {{ props.row.totalRemittances }}
        </span>
          <span v-else class="col common">
          {{ props.row.totalRemittancesMLC }}
        </span>
        </b-table-column>
        <b-table-column field="shipping" :label="$t('commission')" v-slot="props">
        <span class="col common">
          {{ props.row.totalDealerCommission }}
        </span>

        </b-table-column>
        <b-table-column field="canceled" :label="$t('canceled')" v-slot="props">
        <span v-if="currentCurrency === CURRENCY.USD" class="col common">
          {{ props.row.canceledAmountUSD }}
        </span>
          <span v-else-if="currentCurrency === CURRENCY.CUP" class="col common">
          {{ props.row.canceledAmount }}
        </span>
          <span v-else class="col common">
          {{ props.row.canceledAmountMLC }}
        </span>
        </b-table-column>
        <b-table-column field="transfer" :label="$t('transfer')" v-slot="props">
        <span v-if="currentCurrency === CURRENCY.USD" class="col common">
          {{ props.row.transferredAmountUSD }}
        </span>
          <span v-else-if="currentCurrency === CURRENCY.CUP" class="col common">
          {{ props.row.transferredAmount }}
        </span>
          <span v-else class="col common">
          {{ props.row.transferredAmountMLC }}
        </span>
        </b-table-column>
        <b-table-column field="expenses" :label="$t('expenses')" v-slot="props">
        <span v-if="currentCurrency === CURRENCY.USD" class="col common">
          {{ props.row.expensesUSD }}
        </span>
          <span v-else-if="currentCurrency === CURRENCY.CUP" class="col common">
          {{ props.row.expenses }}
        </span>
          <span v-else class="col common">
          {{ props.row.expensesMLC }}
        </span>
        </b-table-column>
        <b-table-column field="available" :label="$t('available')" v-slot="props">
          <b-tag type="is-pink-opacity" rounded>
            <span v-if="currentCurrency === CURRENCY.USD" class="text-status pink"> {{ props.row.aviableBalanceUSD }}</span>
            <span v-else-if="currentCurrency === CURRENCY.CUP" class="text-status pink"> {{ props.row.aviableBalance }}</span>
            <span v-else class="text-status pink"> {{ props.row.aviableBalanceMLC }}</span>
          </b-tag>
        </b-table-column>

        <template #footer>
          <th>
            <span v-if="currentCurrency === CURRENCY.EUR" class="col resumen is-flex is-justify-content-center is-uppercase">
              Resumen USD
            </span>
            <span v-else-if="currentCurrency === CURRENCY.CUP" class="col resumen is-flex is-justify-content-center is-uppercase">
              Resumen CUP
            </span>
            <span v-else-if="currentCurrency === CURRENCY.MLC" class="col resumen is-flex is-justify-content-center is-uppercase">
              Resumen MLC
            </span>
          </th>

          <th>
            <b-tag type="is-tag-success " rounded>
              <span class="text-status success is-uppercase"> ${{ totals.initialAmount }}</span>
            </b-tag>
          </th>
          <th>
        <span class="col common">
          $ {{ totals.receivedAmount }}
        </span>

          </th>
          <th>
            <b-tag type="is-tag-success " rounded>
              <span class="text-status success"> ${{ totals.deliveredAmount }}</span>
<!--              <span v-else class="text-status success"> ${{ totals.deliveredAmount }}</span>-->
            </b-tag>
          </th>
          <th>
<!--        <span v-if="currency" class="col common">-->
<!--          {{ totals.totalRemittancesUSD }}-->
<!--        </span>-->
            <span class="col common">
          {{ totals.totalRemittances }}
        </span>
          </th>
          <th>
        <span class="col common">
           $ {{ totals.totalDealerCommission }}
        </span>
          </th>
          <th>
<!--        <span v-if="currency" class="col common">-->
<!--           $ {{ totals.canceledAmountUSD }}-->
<!--        </span>-->
            <span class="col common">
           $ {{ totals.canceledAmount }}
        </span>
          </th>

          <th>
<!--        <span v-if="currency" class="col common">-->
<!--           $ {{ totals.transferredAmountUSD }}-->
<!--        </span>-->
            <span class="col common">
           $ {{ totals.transferredAmount }}
        </span>
          </th>
          <th >
          <span class="col common">
            $ {{ totals.expenses }}
          </span>
          </th>
          <th>
            <b-tag type="is-pink-opacity" rounded>
<!--              <span v-if="currency" class="text-status pink"> ${{ totals.aviableBalance }}</span>-->
              <span class="text-status pink"> ${{ totals.aviableBalance }}</span>
            </b-tag>
          </th>
        </template>
      </b-table>
      <paginate v-if="report"
                :count="report.totalRecords"
                :per_page="report.itemsPerPage"
                :current-page="report.currentPage"
                :total-pages="report.totalPages"
                @first="getLast(true)"
                @previous="getDailyCredit(report.currentPage - 1,report.itemsPerPage)"
                @next="getDailyCredit(report.currentPage + 1,report.itemsPerPage)"
                @last="getLast"
                @perPage="getDailyCredit(report.currentPage,$event)"
                @search="getDailyCredit($event,report.itemsPerPage)"
      />
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions} from "vuex";
import {CURRENCY} from "@/enum/currency";
import dolar from "@/assets/img/dolar_dash.png";

export default {
  name: "DailyCreditComponent",
  components: {Paginate, Breadcrumb},
  data() {
    return {
      CURRENCY,
      currencyList: ['MLC', 'CUP', 'USD'],
      currentCurrency: null,
      dolar,
      search: null,
      per_page: 5,
      dates: [],
      report: [],
      totalUsd: null,
      totalCup: null,
      totalMLC: null,
      totals: null,
      currency: true,
    }
  },
  methods: {
    ...mapActions({
      fetchDailyCredit: 'FETCH_DEALER_DAILY_CREDIT'
    }),
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.report.totalPages
      this.getDailyCredit(page, this.report.itemsPerPage)
    },
    getDailyCreditByCurrency(currency) {
      this.currentCurrency = currency
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    getDailyCredit(current = 1, record = 10, id = null, dateTime = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        dateTime: dateTime,
        query: query,
      };
      return new Promise(resolve => {
        this.fetchDailyCredit(data)
            .then(value => {
              this.report = value.report
              this.totalUsd = value.totalInUSD
              this.totals = this.totalUsd
              this.totalCup = value.totalInCUP
              this.totalMLC = value.totalInMLC
              resolve(value);
            })
      })

    },
    searchIconClick() {
      let query = this.search ? `&query=${this.search}` : null
      this.getDailyCredit(1, 10, null, null, query)

    },
  },
  watch: {
    search: function () {
      if (this.search.length === 0) {
        this.searchIconClick()
      }
    },
    currentCurrency: function () {
      switch (this.currentCurrency) {
        case CURRENCY.CUP:
          this.totals = this.totalCup;
          break;
        case CURRENCY.USD:
          this.totals = this.totalUsd;
          break;
        case CURRENCY.MLC:
          this.totals = this.totalMLC;
          break;
      }
    }
  },
  created() {
    this.getDailyCredit()
        .then(value => {
          console.log(value);
          this.report = value.report
          this.totalUsd = value.totalInUSD
          this.totals = this.totalUsd
          this.totalCup = value.totalInCUP
          this.totalMLC = value.totalInMLC
        })
  }

}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.resumen {
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #0B2B46;

  }

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }


  &.common {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #0B2B46;
  }

}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.success {
    color: #289BA4;
  }

  &.dealer {
    color: #4D5EC3;
  }

  &.pink {
    color: #C65E89;
  }

}

.currency {
  font-weight: 600;
  font-size: 40px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #666BAA;
}

</style>